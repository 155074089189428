/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
// import PropTypes from "prop-types"
import Div100vh from 'react-div-100vh'
// import { useStaticQuery, graphql } from "gatsby"

import "./normalize.css"
import "./layout.css"
import "./grid.css"

import Header from "components/header"
import Footer from "components/footer"

class Layout extends React.Component {  
  
  render() {
    return (
      <Div100vh>
          <Header />
          <main>{this.props.children}</main>
          <Footer />
      </Div100vh>
    );
  }
}

export default Layout
