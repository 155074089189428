import React from "react"
import { css } from 'emotion'
import fetch from 'node-fetch'
import PropTypes from 'prop-types'


import theme from 'utils/theme'
import PrimaryButton from 'components/PrimaryButton'

const FormStyle = {
  margin: `0`,
}

const EmailInputStyle = {
  fontFamily: 'var(--font-family)',
  fontSize: theme.fontSize.small,
  fontWeight: theme.fontWeight.regular,
  color: theme.colors.black90,
  padding: `12px 16px`,
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.colors.black30}`,
  borderRadius: `4px`,
  minWidth: `240px`,

  [`${theme.responsiveKeys.mobile}`]: {
    width: `100%`
  },
}

const EmailConfirmationStyle = {
  margin: `16px 0 0 0`,
  display: `flex`,
  opacity: `0`,
  transition : `all .3s`,
}

const EmailConfirmationToastStyle = {
  margin: `0`,
  textAlign: `center`,
  fontWeight: theme.fontWeight.semibold,
  fontFamily: `var(--title-font-family)`,
  textTransform: `uppercase`,
  color: theme.colors.black90,
  fontSize: theme.fontSize.xsmall,
  lineHeight: `1em`,
  letterSpacing: `1px`,
}

const PrimaryButtonStyle = {
  margin: `0 0 0 16px`,
  width: `auto`,

  [`${theme.responsiveKeys.mobile}`]: {
    width: `100%`,
    margin: `16px 0 0 0`,
  },
}


class EmailInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      showConfirmation: false,
      inputValue: '',
    }

    this.saveEmail = this.saveEmail.bind(this);
    this.showToast = this.showToast.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  };

  handleChange(event) {
    this.setState({
      inputValue: event.target.value
    });
  };

  handleSubmit(event) {
    event.preventDefault();

    const email = event.target[0].value;
    this.setState({
      email: email
    });

    this.showToast();
    this.saveEmail(email);
  };

  saveEmail(email) {

    const isTesting = false

    if (isTesting) {
      console.log(`Signed up new user: ${email}`);      
    } else {

      const convertKitAPIKey = 'OZNULAz3VJxPcRf_GlY5gA'
      const convertKitFormId = '1153686'
      const submissionType = this.props.type

      const requestBody = {
        'api_key': convertKitAPIKey,
        'email': email,
        tags: [submissionType],
      };

      const requestBodyString = JSON.stringify(requestBody)

      const data = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: requestBodyString,
      };

      fetch(`https://api.convertkit.com/v3/forms/${convertKitFormId}/subscribe`, data)
        .then(res => res.json())
        .then(json => {
          // console.log("\nConvertKit response\n");
          // console.log(json);
        });
    }
  };

  showToast() {
    this.setState({
      showConfirmation: true
    });

    setTimeout(function() {
      this.setState({
        showConfirmation: false,
        inputValue: '',
      });
    }.bind(this), 3000)
  };

  render() {
    const opacity = this.state.showConfirmation ? 1 : 0
    const ToastStyle = {
      opacity: opacity
    }

    return (
      <div>
        <form style={FormStyle} onSubmit={this.handleSubmit}>
          <input className={css(EmailInputStyle)} type="email" name="email" placeholder="Enter your email" value={this.state.inputValue} onChange={this.handleChange} />
          <PrimaryButton style={PrimaryButtonStyle} title={"Submit"} onClick={() => {}} />
        </form>
        <div style={{...EmailConfirmationStyle, ...ToastStyle}}>
          <p style={EmailConfirmationToastStyle}>Email submitted! 👍</p>
        </div>
      </div>
    );
  };
};

EmailInput.propTypes = {
  type: PropTypes.string.isRequired,
};

export default EmailInput
