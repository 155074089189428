/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import OpenGraphImage from 'assets/images/open-graph-hero.png'

function SEO({ lang, meta, url, title, image, description }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            url
            description
            author
            userName
            userEmail
            userTwitter
            userLocation
          }
        }
      }
    `
  );

  const metaUrl = url || site.siteMetadata.url
  const metaTitle = title ? `${title} | ${site.siteMetadata.title}` : site.siteMetadata.title
  const metaImage = image || OpenGraphImage
  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'og:image',
          content: `https://deconstructingdesign.com${metaImage}`,
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          type: `og:url`,
          content: metaUrl,
        },
        {
          type: `og:image:width`,
          content: metaImage ? `1200px` : `100px`,
        },
        {
          type: `og:image:height`,
          content: metaImage ? `630px` : `100px`,
        },
        {
          type: `og:locale`,
          content: `en_US`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          type: `twitter:site`,
          content: site.siteMetadata.userTwitter,
        },
      ].concat(meta)}
    />
  )
}

// title: "Deconstructing Design",
// logo: "",
// url: "https://www.deconstructingdesign.com",
// description: "Creating the best place to start learning design online.",
// author: "Deconstructing Design",
// userName: "Patrick Reynolds",
// userEmail: "patrick@deconstructingdesign.com",
// userTwitter: "@decon_design",
// userLocation: "San Francisco, CA",

  // <meta property="og:type" content="article">
  // <meta property="og:title" content="News and updates">
  // <meta property="og:url" content="https://seths.blog/news/">
  // <meta property="og:description" content="Special messages from Seth about free stuff, time-sensitive offers, and other important updates. Subscribe here to get each one delivered to your inbox.">
  // <meta property="og:site_name" content="Seth's Blog">
  // <meta property="og:image" content="https://seths.blog/wp-content/themes/godin/img/og-images/seth.jpg">
  // <meta property="og:locale" content="en_US">

  // <meta name="twitter:text:title" content="News and updates">
  // <meta name="twitter:card" content="summary_large_image">
  // <meta property="og:image:width" content="1200px">
  // <meta property="og:image:height" content="628px">
  // <meta name="twitter:site" content="@ThisIsSethsBlog">

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
