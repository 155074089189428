import { Link } from "gatsby"
import React from "react"
import styled from 'styled-components'
import theme from 'utils/theme'
import { css } from 'emotion'

import DeconstructingDesignLogo from 'assets/images/logo-deconstructing-design-header.svg'

const SiteHeader = styled.header`
  background-color: transparent;
  border-bottom: 1px solid ${theme.colors.black20}
`

const SiteLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  width: fit-content;
  height: fit-content;

  ${theme.responsiveKeys.mobile} {
    flex-direction: row;
    width: auto;
    height: auto;
  }
`

const SiteLogoStyle = {
  height: `calc(var(--header-height) - 32px)`,
  width: `calc(var(--header-height) - 32px)`,
  margin: `16px 0 0 0`,

  [`${theme.responsiveKeys.mobile}`]: {
    margin: `16px 0px 0px 0px`,
    height: `calc(var(--header-height) - 32px)`,
    width: `calc(var(--header-height) - 32px)`,
  },  
}

const NavBar = styled.div`
  display: flex;
  justify-content: flex-end;

  ${theme.responsiveKeys.mobile} {
    justify-content: center;
  }
`

const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 32px 0px 32px 40px;

  ${theme.responsiveKeys.mobile} {
    margin: 24px 16px;
  }
`

const NavItemLinkStyle = {
  fontWeight: theme.fontWeight.semibold,
  fontFamily: `var(--title-font-family)`,
  textTransform: `uppercase`,
  color: theme.colors.black90,
  textDecoration: `none`,
  fontSize: theme.fontSize.xsmall,
  lineHeight: `1.3em`,
  letterSpacing: `2px`,

  // '&:hover': {
  //   color: `${theme.colors.black90}`,
  //   transitionDuration: `0.2s`,
  //   borderBottom: `1px solid ${theme.colors.black90}`,
  // },
}

const HeaderLogoStyle = {
  display: `flex`,
  height: `100%`,
  justifyContent: `flex-start`,

  [`${theme.responsiveKeys.mobile}`]: {
    justifyContent: `center`,
  },
}

const LinkStyle = {
  display: `flex`,
}

const Header = () => (
  <SiteHeader>
    <div className="container">
      <div className="row">
        <div className={css(HeaderLogoStyle)} className="col-6 col-12-sm">
          <SiteLogo>
            <Link style={LinkStyle} to="/">
              <img className={css(SiteLogoStyle)} src={DeconstructingDesignLogo} alt={`Deconstructing Design Logo`}/>
            </Link>
          </SiteLogo>
        </div>
        <div className="col-6 col-12-sm">
          <NavBar>
            <NavItem>
              <Link to="/blog" style={NavItemLinkStyle}>
                 Blog
              </Link>
            </NavItem>
            <NavItem>
              <Link
                  to="/resources"
                  style={NavItemLinkStyle}
                >
                 Resources
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to="/about"
                style={NavItemLinkStyle}
              >
                About
              </Link>
            </NavItem>
          </NavBar>
        </div>
      </div>
    </div>
  </SiteHeader>
);

export default Header
