
let theme = {
  colors: {
    white: '#FFFFFF',
    black05: `#FAFAFA`,
    black10: '#F5F8FC',
    black20: '#F2F4F5',
    black30: '#D5D9DE',
    black40: '#ABB4BE',
    black50: '#9DA6B0',
    black60: '#858E99',
    black70: '#6F7A87',
    black80: '#495662',
    black90: '#373E47',
    black100: '#23292F',
    accent: `#1EB5E9`,
  },
  margin: {
    xsmall: '4px',
    small: '8px',
    medium: '16px',
    large: '24px',
    xlarge: '40px',
    xxlarge: '64px',
  },
  padding: {
    xsmall: '4px',
    small: '8px',
    medium: '16px',
    large: '24px',
    xlarge: '40px',
    xxlarge: '64px',
  },
  fontSize: {
    xsmall: `12px`,
    small: `14px`,
    medium: `16px`,
    large: `20px`,
    xlarge: `24px`,
    xxlarge: `32px`,
    xxxlarge: `40px`,
    title: `48px`,
  },
  fontWeight: {
    light: '300',
    regular: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    heavy: '800',
    black: '900',
  },
  responsiveSizes: {
    small: `320px`,
    medium: `375px`,
    large: `768px`,
  },
  responsiveKeys: {
    mobile: `@media only screen and (max-width: 813px)`,
    ipad: `@media only screen and (min-width: 813px) and (max-width: 1024px)`,
  }
}

export default theme