import { Link } from "gatsby"
import React from "react"
import { css } from 'emotion'

import theme from "utils/theme"
import EmailInput from "components/EmailInput"

const FooterStyle = {
  padding: `64px 0px 40px 0`,
  backgroundColor: `#FFEC85`,
  flexShrink: 0,

  [`${theme.responsiveKeys.mobile}`]: {
    padding: `24px 0px 24px 0`,
  },
};

const RowStyle = {
  display: `flex`,

  [`${theme.responsiveKeys.mobile}`]: {
    display: `block`,
  },
};

const CopyrightStyle = {
  fontWeight: theme.fontWeight.regular,
  fontSize: theme.fontSize.xsmall,
  color: theme.colors.black90,
  lineHeight: `1.6em`,
  textAlign: `center`,
};

const ColumnTitleStyle = {
  padding: `0 0 24px 0`,
}

const ListStyle = {
  height: `140px`,
  display: `flex`,
  flexDirection: `column`,
  flexWrap: `wrap`,
  listStyleType: `none`,
  margin: `0px`,

  [`${theme.responsiveKeys.mobile}`]: {
    display: `block`,
    height: `104px`,
    margin: `0 -2% 0 -2%`,
  },
};

const CopyrightContainerStyle = {
  display: `flex`,
  justifyContent: `center`,
  marginTop: `40px`,

  [`${theme.responsiveKeys.mobile}`]: {
    marginTop: `24px`,
  },
};

const ListItemStyle = {
  fontFamily: `var(--font-family)`,
  padding: `0px 0px 16px 0px`,
  color: theme.colors.black90,
  fontWeight: theme.fontWeight.regular,
  lineHeight: `1.2em`,
  cursor: `pointer`,

  [`${theme.responsiveKeys.mobile}`]: {
    
  },

  '&:hover': {
  },
};

const ListItemLinkStyle = {
  fontFamily: `var(--font-family)`,
  color: theme.colors.black90,
}

const NewsletterSectionStyle = {
  padding: `0 0 24px 0`,

  [`${theme.responsiveKeys.mobile}`]: {
    padding: `0 0 16px 0`,
  },
}

const Footer = ({ siteTitle }) => (
  <footer className={css(FooterStyle)}>
    <div className="container">
      <div className={`${css(RowStyle)} row`}>
        <div className="col-6 col-12-sm">
          <div className="row">
            <h3 style={ColumnTitleStyle}>Pages</h3>
            <ul className={css(ListStyle)}>
              <li className={`${css(ListItemStyle)} col-6 col-6-sm`}><Link style={ListItemLinkStyle} to="/blog">Blog</Link></li>
              <li className={`${css(ListItemStyle)} col-6 col-6-sm`}><Link style={ListItemLinkStyle} to="/resources">Resources</Link></li>
              <li className={`${css(ListItemStyle)} col-6 col-6-sm`}><Link style={ListItemLinkStyle} to="/about">About</Link></li>
              <li className={`${css(ListItemStyle)} col-6 col-6-sm`}><a style={ListItemLinkStyle} href="https://www.twitter.com/deecondesign/">Twitter</a></li>
              <li className={`${css(ListItemStyle)} col-6 col-6-sm`}><a style={ListItemLinkStyle} href="https://www.instagram.com/deecondesign/">Instagram</a></li>
            </ul>
          </div>
        </div>
        <div className={`col-6 col-12-sm`}>
          <h3 className={css({...ColumnTitleStyle, ...NewsletterSectionStyle})}>Newsletter</h3>
          <EmailInput type={'footer'}/>
        </div>
      </div>
      <div className="row">
        <div className={css(CopyrightContainerStyle)} className="col-12">
          <p style={CopyrightStyle}>Designed and built by Patrick Reynolds<br />Copyright © 2020</p>
        </div>
      </div>
    </div>
  </footer>
);

/*
              <li className={`${css(ListItemStyle)} col-6 col-6-sm`}><a style={ListItemLinkStyle} href="">Podcast</a></li>
              <li className={`${css(ListItemStyle)} col-6 col-6-sm`}><a style={ListItemLinkStyle} href="">YouTube</a></li>
*/

export default Footer
