import React from "react"

import theme from 'utils/theme'
import { css } from 'emotion'

const PrimaryButtonStyle = {
  fontFamily: 'var(--title-font-family)',
  margin: `0`,
  padding: `12px 24px`,
  backgroundColor: theme.colors.accent,
  borderRadius: `4px`,
  textTransform: 'uppercase',
  fontSize: theme.fontSize.small,
  fontWeight: theme.fontWeight.medium,
  color: theme.colors.white,
  letterSpacing: `1px`,
  border: `none`,
  cursor: `pointer`,
}

class PrimaryButton extends React.Component {
  render() {
    return (
      <button className={css({...PrimaryButtonStyle,...this.props.style})} onClick={this.props.handleClick}>
        {this.props.title}
      </button>
    );
  };
};

// <button className={`${css(PrimaryButtonStyle)} ${this.props.className}`} onClick={this.props.handleClick}>

export default PrimaryButton